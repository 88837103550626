import React from 'react'

export default [{
    path: '/',
    name: 'Home',
    component: React.lazy(() =>
        import ("views/Home"))
}, {
    path: '/about-us',
    name: 'About',
    component: React.lazy(() =>
        import ("views/About"))
}, {
    path: '/services',
    name: 'Services',
    component: React.lazy(() =>
        import ("views/Services"))
}, {
    path: '/services/web',
    name: 'Web',
    component: React.lazy(() =>
        import ("views/Services/Web"))
}, {
    path: '/services/apps',
    name: 'Apps',
    component: React.lazy(() =>
        import ("views/Services/Apps"))
}, {
    path: '/services/CRMS',
    name: 'CRMS',
    component: React.lazy(() =>
        import ("views/Services/CRMS"))
}, {
    path: '/services/ecommerce',
    name: 'Ecommerce',
    component: React.lazy(() =>
        import ("views/Services/Ecommerce"))
}, {
    path: '/services/software',
    name: 'Software',
    component: React.lazy(() =>
        import ("views/Services/Software"))
}, {
    path: '/contact',
    name: 'Contact',
    component: React.lazy(() =>
        import ("views/Contact"))
}, {
    path: '/news',
    name: 'News',
    component: React.lazy(() =>
        import ("views/News"))
}, {
    path: '/news/details',
    name: 'News Details',
    component: React.lazy(() =>
        import ("views/NewsDetails"))
}]