import {
    TOGGLE_APP_NAV_DRAWER
} from './types'

const initialState = {
    isActiveAppNavDrawer: false
}

const systemReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_APP_NAV_DRAWER:
            if (action.payload !== undefined) {
                return {...state, isActiveAppNavDrawer: action.payload }
            } else {
                return {...state, isActiveAppNavDrawer: !state.isActiveAppNavDrawer }
            }
        default:
            return state
    }
}

export default systemReducer