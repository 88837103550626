import i18n from 'i18next'
import detector from "i18next-browser-languagedetector";
// import backend from "i18next-xhr-backend";
import { initReactI18next } from 'react-i18next';

import EN from './locales/en/common.json'
import ZH from './locales/zh/common.json'
import CN from './locales/cn/common.json'

export const resources = {
    zh: {
        translation: ZH
    },
    en: {
        translation: EN
    },
    cn: {
        translation: CN
    }
} as const
i18n.use(detector)
// i18n.use(backend)
i18n.use(initReactI18next)
    .init({
        resources,
        fallbackLng: "zh",
        // keySeparator: false, //keybased catalog
        interpolation: {
            escapeValue: false
        },
        react: { //wait namespaces
            wait: true
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage'],
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            caches: ['cookie', 'localStorage'],
        }
    });

export default i18n