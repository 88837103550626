import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/main.sass';
import { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import routes from "router/index.js";
import SuspenseLoading from "components/Module/suspenseLoading/SuspenseLoading";
import Cookies from 'universal-cookie';

const Header = loadable(() => import("components/Nav/Header"));
const Footer = loadable(() => import("components/Nav/Footer"));

function addLangClass() {
  const cookies = new Cookies();
  const langCode = cookies.get("i18next");
  return langCode;
}

const App = () => {
  return (
    <div className={`App lang-${addLangClass()}`}>
      <Header />
      <Suspense fallback={<SuspenseLoading />}>
          <Switch>
              {routes.map(({ path, component }, index) => (
                  <Route
                      key={`app-view-${index}`}
                      exact
                      path={path}
                      component={component}
                  >
                  </Route>
              ))}
              <Redirect to="/" />
          </Switch>
      </Suspense>
      <Footer />
    </div>
  );
};

export default App;