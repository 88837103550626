import "./suspenseLoading.sass";
import { withTranslation } from 'react-i18next'
import Loading from 'assets/img/loading.svg';

const SuspenseLoading =  ({ t }: { t:any }) => {

  return (
    <div className="loading"><img src={Loading} /></div>
  );
};

export default withTranslation()(SuspenseLoading);