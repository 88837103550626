import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";

import "./i18n";
import App from './App';
import AOS from 'aos';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import rootReducer from "store/index";

AOS.init({
  duration: 1000,
  // easing: 'ease-out-back',
  // delay: 1000
})

const store = createStore(rootReducer);

ReactDOM.render(
  <React.Fragment>
    <HashRouter>
      <Provider store={store}>
        <App />
      </Provider> 
    </HashRouter>
  </React.Fragment>,
  document.getElementById('root')
);

serviceWorkerRegistration.unregister();

reportWebVitals();
